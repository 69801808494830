.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  background-color: #5F8E0C;
  border-color: #5F8E0C;
  color: #E6E6DF
}

.btn:hover, .btn:active {
  color: #5F8E0C;
  background-color: #C2C8BF;
  border-color: #C2C8BF; /*set the color you want here*/
}

.border-primary {
  border-color: #5F8E0C!important;
}

.form-control {
  border-color: #C2C8BF!important;
}

.card {
  border-color: #C2C8BF!important;
}

.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
  border-left: 2px solid #5F8E0C;
}

.rt-noData {
  z-index: -20!important;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 51px;
  width: 100%;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  max-height: 100px;
  overflow-y: auto;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #C2C8BF;
}

@media (min-width: 767px) {
  .grid-container {
    display: grid;
    grid-template-columns: 12fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

@media (max-width: 767px) {
  .grid-container {
    display: grid;
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: auto;
  }
}

@media (min-width: 767px) {
  .grid2-container {
    display: grid;
    grid-template-columns: 12fr 1fr;
    grid-template-rows: auto;
  }
}

@media (max-width: 767px) {
  .grid2-container {
    display: grid;
    grid-template-columns: 4fr 1fr;
    grid-template-rows: auto;
  }
}

.grid4-container {
  display: grid;
  grid-template-columns: 12fr 1fr 1fr 1fr;
  grid-template-rows: auto;
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0,0,0,.02);
  white-space: normal;
}

.ReactTable {font-size:12px}

.ReactTable .rt-thead .rt-td.-cursor-pointer, .ReactTable .rt-thead .rt-th.-cursor-pointer{white-space:normal}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #000;
  border-color: transparent transparent #5F8E0C;
  background-color: transparent;
}